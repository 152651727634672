import React from "react";
import { useNavigate } from "react-router-dom";
import userImage from "../../assets/images/about/avatar.jpg";
import { FaLinkedinIn } from "react-icons/fa";
import PageTitle from "../../Share/PageTitle";

const HomePage = () => {
  const history = useNavigate();
  return (
    <>
      <PageTitle title="Home"></PageTitle>
      {/* End pagetitle */}

      <div
        className="flex flex-col items-center h-[100vh] md:h-[90vh] lg:h-[80vh]  xl:h-[71vh]  justify-center"
        data-aos="fade"
      >
        <img
          className="rounded-full w-[250px] h-[250px] 2xl:w-[280px] 2xl:h-[280px]"
          style={{objectFit:"cover", objectPosition:"bottom"}}
          src={userImage}
          alt=""
        />
        <h3 className="mt-6 mb-1 text-5xl font-semibold dark:text-white">
          Sanjin Omerović
        </h3>
        <p className="mb-4 text-[#7B7B7B]">Product owner/manager with tech background</p>
        {/* Avatar Info End */}

        {/* Social information start */}
        <div className="flex space-x-3">
          <a
            href="https://ba.linkedin.com/in/sanjinomerovic"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#1C9CEA]">
              <FaLinkedinIn />
            </span>
          </a>
        </div>
        {/* Social information start */}

        {/* Dowanload button start */}
        <button 
        onClick={() => history('/contact')}
        className="flex items-center bg-gradient-to-r from-[#33A799] to-[#00F5AB] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#33A799]  to-[#00F5ABef] px-8 py-3 text-lg text-white rounded-[35px] mt-6">
          Contact me
        </button>

        {/* Dowanload button End */}
      </div>
    </>
  );
};

export default HomePage;
